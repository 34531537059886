import React from 'react'

interface IuseGroupSelect<T> {
  isMulti?: boolean;
  defaultValue: T[];
}

function useGroupSelect<T>({ isMulti = false, defaultValue }: IuseGroupSelect<T>): [T[], (value: T) => void] {
  const [selected, setSelected] = React.useState<T[]>(defaultValue);
  let selectedIcons: T[];

  const handleSelected = (value: T): void => {
    if (isMulti) {
      selectedIcons = selected.slice();
      // If the item is not available add it to the list, otherwise remove it from the list.
      if (selectedIcons.indexOf(value) === -1) {
        selectedIcons.push(value);
      } else {
        selectedIcons.splice(selected.indexOf(value), 1);
      }
    } else {
      selectedIcons = [value];
    }
    setSelected(selectedIcons);
  }

  React.useEffect(() => {  // This is to set back the state if the settings are discarded
    setSelected(defaultValue)
  }, [JSON.stringify(defaultValue)])

  return [selected, handleSelected];
}

export default useGroupSelect;
