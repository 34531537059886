
import { useAppBridge } from '@shopify/app-bridge-react'
import { useRouter } from 'next/router'
import React from 'react'
import useSWR from 'swr'
import { withAxiosAuth } from 'utils/fetch'
import { captureException } from '@sentry/browser'


export default function usePreviewUrl() {
  const app = useAppBridge()
  const router = useRouter()
  const { data, error } = useSWR('/api/previewUrl', url => withAxiosAuth(app).get(url).then(res => res.data), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })
  const [previewUrl, setPreviewUrl] = React.useState(`https://${router.query.shop as string}`)

  if (error) {
    captureException(error)
  }

  React.useEffect(() => {
    if (data) {
      setPreviewUrl(data.previewUrl)
    }
  }, [data])
  return previewUrl
}
