import { getSessionToken } from "@shopify/app-bridge-utils";
import { ClientApplication } from '@shopify/app-bridge'
import axios from "axios";

// intercept all requests on this axios instance
export function withAxiosAuth(app: ClientApplication<any>) {
  const instance = axios.create();
  instance.interceptors.request.use(
    async function (config) {
      const token = await getSessionToken(app)
      config.headers['Authorization'] = `Bearer ${token}`;
      return config
    }
  );
  return instance
}

export const fetchWithToken = (url: string, app: ClientApplication<any>) => withAxiosAuth(app).get(url).then(res => res.data)
