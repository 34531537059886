
/**
 *  It tracks the values that have been "dirtied" by pushing them onto a list,
 *  and if the value of the payload is equal to its initial value, then that field is removed from the dirtyList
 * @param dirtyList List to keep track of dirty fields
 * @param settingsInitialValue Initial state of appSettings object. For example, "badgeSize"
 * @param payloadValue Value of action.payload
 */
export default function dirtyRegister<T>(dirtyList: string[], settingsInitialValue: { [x: string]: T }, payloadValue: T): string[] {
  const settingsKey = Object.keys(settingsInitialValue)[0]
  const initialSettings = typeof settingsInitialValue[settingsKey] === "object" ? JSON.stringify(settingsInitialValue[settingsKey]) : settingsInitialValue[settingsKey];
  const payload = typeof payloadValue === "object" ? JSON.stringify(payloadValue) : payloadValue;
  const isSettingsKeyinDirtyList = dirtyList.includes(settingsKey);
  if (payload !== initialSettings && !isSettingsKeyinDirtyList) {
    dirtyList.push(settingsKey);
  } else if (isSettingsKeyinDirtyList && payload === initialSettings) {
    dirtyList.splice(dirtyList.indexOf(settingsKey), 1);
  }
  return dirtyList;
}
