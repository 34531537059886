import { HSBAColor } from "@shopify/polaris";

export default function hsbaToHsla(HSBAColor: HSBAColor) {
  /**
   * @param {object} HSBAColor {hue: number (min: 0 - max: 360), saturation: number (min: 0, max: 1), brightness: number (min: 0, max: 1), alpha: number (min: 0, max: 1)}
   */

  let { hue, saturation, brightness, alpha } = HSBAColor;

  const lightness = ((2 - saturation) * brightness) / 2;

  if (lightness != 0) {
    if (lightness == 1) {
      saturation = 0;
    } else if (lightness < 0.5) {
      saturation = (saturation * brightness) / (lightness * 2);
    } else {
      saturation = (saturation * brightness) / (2 - lightness * 2);
    }
  }

  return { hue, saturation, lightness, alpha };
}
