import { getIconInfo } from "utils";
import { BadgeStyle } from 'types'

/**
 * @param normalisedIconName Normalised icon name. For example: payment_visa
 * @param badgeStyle Badge style. For example: card_mono
 * @returns Denoramlised icon name. For example: payment_card_mono_visa
 * */
export default function denormaliseIconName(normalisedIconName: string, badgeStyle: BadgeStyle): string {
  const splitNormalisedIconName = normalisedIconName.split('_')
  const iconCategory = splitNormalisedIconName[0]
  const iconName = splitNormalisedIconName.slice(1,).join('_')
  return `${iconCategory}_${badgeStyle}_${iconName}`;
}
