interface IconInfo {
  category: "payment" | "trust",
  shape: "icon" | "card",
  colourStyle: "mono" | "original",
  caption: string
}

/**
 *
 * @param iconFullName Item to split
 * @returns Split item as an object. For example: payment_card_mono_visa -> {category: "payment", shape: "card", "colourStyle": "mono", "iconName": "visa"}
 */
export default function getIconInfo(iconFullName: string): IconInfo {
  const split_icon_name = iconFullName.split('_')
  const iconInfo: IconInfo = {
    category: split_icon_name[0] as IconInfo["category"],
    shape: split_icon_name[1] as IconInfo["shape"],
    colourStyle: split_icon_name[2] as IconInfo["colourStyle"],
    caption: split_icon_name.slice(3,).join(' ')
  }
  return iconInfo;
}
