type IExceptionMap = {
  from: string;
  to: string;
};

/**
 * Converts icon name into caption.
 * @param iconName icon name
 */
export default function captionise(iconName: string) {
  const exceptionMap: IExceptionMap[] = [
    {
      from: "ssl",
      to: "SSL",
    },
    {
      from: "jcb",
      to: "JCB",
    },
    {
      from: "uk",
      to: "UK",
    },
    {
      from: "usa",
      to: "USA",
    },
    {
      from: "sepa",
      to: "SEPA",
    },
    {
      from: "spei",
      to: "SPEI",
    },
    {
      from: "ideal",
      to: "iDEAL",
    },
    {
      from: "percent",
      to: "%",
    },
    {
      from: "mb",
      to: "MB"
    },
    {
      from: "wechat",
      to: "WeChat",
    },
  ];
  const tokens = iconName.split(" ").map((token) => {
    let convertedToken = null;
    const exceptionTitleise = ["and", "or", "but", "in", "on", "for", "up"];
    exceptionMap.forEach((exceptionToken) => {
      if (token.includes(exceptionToken.from)) {
        convertedToken = token.replace(exceptionToken.from, exceptionToken.to);
      }
    });
    if (convertedToken) {
      return convertedToken;
    }
    if (exceptionTitleise.includes(token)) {
      return token;
    }
    return token[0].toUpperCase() + token.slice(1);
  });
  return tokens.join(" ");
}
